import * as React from "react";
import Navigation from "./components/Navigation";
import { Container } from "@mui/material";
import Examplan from "./components/Examplan";

const App = () => (
  <>
    <Navigation />
    <Container
      maxWidth="lg"
      component="main"
      sx={{ padding: "2em" }}
    >
      <Examplan />
    </Container>
  </>
);

export default App;
