import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import rowData from "./exams.json";

const columns = [
    { field: "date", headerName: "Datum", width: 150 },
    { field: "alternativeDate", headerName: "Zusatztermin", width: 150 },
    { field: "name", headerName: "Modul", minWidth: 600 }
];

console.log(rowData);

const Examplan = () => (
    <>
        <Typography variant="h3" sx={{ marginBottom: "1em" }}>
            Prüfungsplan
        </Typography>
        <DataGrid
            columns={columns}
            rows={rowData}
            getRowId={(row) => row.name}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 25 }
                }
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                        placeholder: "Suche"
                    },
                }
            }}
        />
        <Typography variant="body1" sx={{ marginTop: "1em" }}>
            Es handelt sich hierbei um <strong>vorläufige</strong> Angaben <strong>ohne</strong> Gewähr!
        </Typography>
    </>
);

export default Examplan;